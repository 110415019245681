import { FC, useEffect } from 'react'

import { useRouter } from 'next/router'
import { StickyContainer } from 'react-sticky'
import ErrorBoundary from 'components/ui/ErrorBoundary'
import classNames from 'classnames'
import { SIZES, useLayoutContext } from 'context/layoutContext'
import { Footer } from 'components/layout/new-layout/Footer'
import { Header } from 'components/layout/new-layout/Header'
import { LeftMenu } from 'components/layout/new-layout/LeftMenu'
import { SFooterWrapper } from 'components/layout/new-layout/Footer/styles'

import ContentPage from '../new-layout/Content'
import ContentLoader from '../../ui/ContentLoader'

import classes from './Layout.module.scss'


interface Props {
  hideMenu?: boolean
  fixedHeader?: boolean
  withHiddenFooter?: boolean
  withoutPadding?: boolean
  isNewPage?: boolean
  custom?: FC<unknown>
}

export const Layout: FC<Props> = (props) => {
  const router = useRouter()

  const {
    toggleNewPage, toggleLeftMenu, leftMenuOpened, newPagesMenuOpened, contentSize, loading, setLoading
  } = useLayoutContext()

  useEffect(() => {
    toggleNewPage(!!props.isNewPage)
    if (!props.isNewPage && leftMenuOpened) toggleLeftMenu(false)
  }, [props.isNewPage])

  useEffect(() => {
    if (props.isNewPage && [SIZES.W1040, SIZES.W688].includes(contentSize)) toggleLeftMenu(newPagesMenuOpened)
  }, [])

  useEffect(() => {
    const handleStart = () => setLoading(true)
    const handleComplete = () => setLoading(false)

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [])

  return (
    <div className={classes.layout}>
      <StickyContainer>
        {!props.hideMenu && (
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
        )}

        <LeftMenu />

        <ContentPage custom={props.custom} loading={loading}>
          <ErrorBoundary>
            <main className={classNames({ [classes.withoutPadding]: props.withoutPadding })}>
              {loading ? (
                <div className={classes.loader}>
                  <ContentLoader
                    style='block'
                    isOpen
                  />
                </div>
              ) : props.children}
            </main>
          </ErrorBoundary>
        </ContentPage>

        {!props.withHiddenFooter && (
          <SFooterWrapper>
            <Footer />
          </SFooterWrapper>
        )}
      </StickyContainer>
    </div>
  )
}
