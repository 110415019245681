export const LANGUAGES = [
  { icon: '/img/langs/ru.svg', name: 'Ru', code: 'ru', fullName: 'Russian' },
  { icon: '/img/langs/en.svg', name: 'En', code: 'en', fullName: 'English' },
  { icon: '/img/langs/uz.svg', name: 'Uz', code: 'uz', fullName: 'Uzbek' },
  { icon: '/img/langs/az.svg', name: 'Az', code: 'az', fullName: 'Azerbaijani' },
  { icon: '/img/langs/tr.svg', name: 'Tr', code: 'tr', fullName: 'Turkish' },
  { icon: '/img/langs/hi.svg', name: 'Hi', code: 'hi', fullName: 'Hindi' },
  { icon: '/img/langs/fa.svg', name: 'Fa', code: 'fa', fullName: 'Persian' },
  { icon: '/img/langs/ua.svg', name: 'Ua', code: 'uk', fullName: 'Ukrainian' },
  { icon: '/img/langs/kk.svg', name: 'Kz', code: 'kk', fullName: 'Kazakh' },
  { icon: '/img/langs/es.svg', name: 'Es', code: 'es', fullName: 'Spanish' },
  { icon: '/img/langs/fr.svg', name: 'Fr', code: 'fr', fullName: 'French' },
  { icon: '/img/langs/hy.svg', name: 'Hy', code: 'hy', fullName: 'Armenian' },
  { icon: '/img/langs/th.svg', name: 'Th', code: 'th', fullName: 'Thai' },
  { icon: '/img/langs/vn.svg', name: 'Vn', code: 'vi', fullName: 'Vietnamese' },
  { icon: '/img/langs/es-MX.svg', name: 'Mx', code: 'es', fullName: 'Mexican' },
  { icon: '/img/langs/es-CL.svg', name: 'Cl', code: 'es-CL', fullName: 'Spanish' },
  { icon: '/img/langs/es-PE.svg', name: 'Pe', code: 'es-PE', fullName: 'Spanish' },
  { icon: '/img/langs/pt.svg', name: 'Pt', code: 'pt', fullName: 'Portuguese' },
  { icon: '/img/langs/br.svg', name: 'Br', code: 'pt-br', fullName: 'Portuguese' },
  { icon: '/img/langs/be.svg', name: 'Be', code: 'be', fullName: 'Belarusian' },
  { icon: '/img/langs/cz.svg', name: 'Cz', code: 'cs-CZ', fullName: 'Czech' },
  { icon: '/img/langs/pl.svg', name: 'Pl', code: 'pl', fullName: 'Polish' },
  { icon: '/img/langs/ro.svg', name: 'Ro', code: 'ro', fullName: 'Romanian' },
  { icon: '/img/langs/bn.svg', name: 'Bn', code: 'bn', fullName: 'Bengali' },
  { icon: '/img/langs/hu.svg', name: 'Hu', code: 'hu-HU', fullName: 'Hungarian' },
  { icon: '/img/langs/fi.svg', name: 'Fi', code: 'fi-FI', fullName: 'Finnish' },
  { icon: '/img/langs/ne.svg', name: 'Ne', code: 'ne', fullName: 'Nepali' },
  { icon: '/img/langs/sw.svg', name: 'Sw', code: 'sw', fullName: 'Swahili' },
  { icon: '/img/langs/de.svg', name: 'De', code: 'de', fullName: 'German' },
  { icon: '/img/langs/it.svg', name: 'It', code: 'it', fullName: 'Italian' },
]
