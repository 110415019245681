import React, { useEffect, useState } from 'react'

import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import classNames from 'classnames'
import { useAppContext } from 'context/AppContext'
import { useLocation } from "react-use"

import {
  BONUSES_LINKS,
  CASINO_LINKS,
  INFORMATION_LINKS_1,
  INFORMATION_LINKS_2,
  LICENSES,
  PAYMENTS,
  PROVIDERS,
  SOCIALS,
  SPORT_LINKS
} from './constants'
import {
  StyledAllRights,
  StyledArrowIcon,
  StyledBottomText,
  StyledColoredFooterBox,
  StyledContent,
  StyledExpandBtn,
  StyledFooterBox,
  StyledLanguageSelect,
  StyledLanguagesDropdown,
  StyledLicenseImg,
  StyledLicensesContainer,
  StyledLink,
  StyledLinksColumn,
  StyledLinksContainer,
  StyledLinksDoubleColumn,
  StyledLinksDoubleColumnContainer,
  StyledLinksTitle,
  StyledLogo,
  StyledPaymentsContainer,
  StyledProviderImg,
  StyledProvidersContainer,
  StyledProvidersRow,
  StyledSocialBtn,
  StyledSocialRow,
  StyledSocials,
  StyledStore,
  StyledStoreImg,
  StyledStores,
  StyledSupportAndLanguagesContainer,
  StyledSupportBtn,
  StyledText
} from './styles'

export const Footer = ({ className = '' }) => {
  const appContext = useAppContext()
  const { t } = useTranslation()
  const location = useLocation()

  const [textExpanded, toggleTextVisibility] = useState(false)
  const [needToHideLicense, toggleNeedToHideLicense] = useState(true)

  const handleExpandBtnClick = () => {
    toggleTextVisibility(state => !state)
  }

  const handleSupportBtnClick = () => {
    appContext.openSupport()
  }

  useEffect(() => {
    toggleNeedToHideLicense(!!location?.host?.includes('richy.casino'))
  }, [])

  return (
    <StyledFooterBox className={className}>
      <StyledColoredFooterBox>
        <StyledContent>
          <StyledLogo src="/img/logo/richy.svg" />

          <StyledLinksContainer>
            <LinksColumn title={t('menu_casino')}
              items={CASINO_LINKS}
            />

            <LinksColumn title={t('menu_sport')}
              items={SPORT_LINKS}
            />

            <LinksColumn title={t('menu_bonuses')}
              items={BONUSES_LINKS}
            />

            <LinksColumn title={t('footer_information_legal')}
              items={[...INFORMATION_LINKS_1, ...INFORMATION_LINKS_2]}
              hideOnDesktop
              alwaysOpened
            />

            <StyledLinksDoubleColumnContainer>
              <StyledLinksTitle>
                {t('footer_information_legal')}
              </StyledLinksTitle>

              <StyledLinksDoubleColumn>
                <StyledLinksColumn>
                  {INFORMATION_LINKS_1.map(link => (
                    <Link href={link.href}
                      key={link.label}
                    >
                      <StyledLink>
                        {t(link.label)}
                      </StyledLink>
                    </Link>
                  ))}
                </StyledLinksColumn>

                <StyledLinksColumn>
                  {INFORMATION_LINKS_2.map(link => (
                    <Link href={link.href}
                      key={link.label}
                    >
                      <StyledLink>
                        {t(link.label)}
                      </StyledLink>
                    </Link>
                  ))}
                </StyledLinksColumn>
              </StyledLinksDoubleColumn>
            </StyledLinksDoubleColumnContainer>
          </StyledLinksContainer>

          <StyledText className={classNames({ full: textExpanded })}>
            {t('footer_main_text').split('\\n').map(line => {
              return (
                <p key={line}>
                  {' '}

                  {line}

                  {' '}
                </p>
              )
            })
            }
          </StyledText>

          <StyledExpandBtn
            onClick={handleExpandBtnClick}
          >
            {textExpanded ? t('footer_show_less') : t('footer_show_more')}
          </StyledExpandBtn>

          <StyledSocialRow>
            <StyledSocials>
              {
                SOCIALS.map(social => (
                  <StyledSocialBtn href={social.href}
                    key={social.href}
                  >
                    <img src={social.image} />
                  </StyledSocialBtn>
                ))
              }
            </StyledSocials>

            <StyledStores>
              <StyledStore href="https://play.google.com/store/apps/details?id=game.richycasinoonline.game">
                <StyledStoreImg src="/img/footer/socials/android.png" />
              </StyledStore>

              <StyledStore href="https://apps.apple.com/us/app/id6447257017">
                <StyledStoreImg src="/img/footer/socials/ios.png"
                  className='ios'
                />
              </StyledStore>
            </StyledStores>

            <StyledSupportAndLanguagesContainer>
              <StyledSupportBtn onClick={handleSupportBtnClick}>
                <img src="/img/layout/footer/support.svg"
                  alt=""
                />

                {t('support_button')}
              </StyledSupportBtn>

              <StyledLanguagesDropdown />

              <StyledLanguageSelect placement='bottom-start' />
            </StyledSupportAndLanguagesContainer>
          </StyledSocialRow>

          <StyledProvidersContainer>
            <StyledProvidersRow>
              {PROVIDERS.slice(0, Math.floor(PROVIDERS.length / 2) + 2).map((provider) => (
                <StyledProviderImg src={provider.image}
                  key={provider.image}
                />
              ))}
            </StyledProvidersRow>

            <StyledProvidersRow>
              {PROVIDERS.slice(Math.floor(PROVIDERS.length / 2) + 3).map((provider) => (
                <StyledProviderImg src={provider.image}
                  key={provider.image}
                />
              ))}
            </StyledProvidersRow>
          </StyledProvidersContainer>

          <StyledPaymentsContainer>
            {PAYMENTS.map((provider) => (
              <StyledProviderImg src={provider.image}
                key={provider.image}
              />
            ))}
          </StyledPaymentsContainer>

          <StyledLicensesContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://licensing.newwavelicensing.ph/validator/7u4yd/06930235-6560-4630-8100-c0604b3cd20b"
            >
              <StyledLicenseImg
                src='https://licensing.newwavelicensing.ph/badge/7u4yd/dc583537-0c80-4666-b109-8132db4bcb2a/generate'
              />
            </a>

            {LICENSES.map(license => (
              <StyledLicenseImg src={license.image}
                key={license.image}
              />
            ))}
          </StyledLicensesContainer>

          <StyledBottomText>
            <p>
              © 2023 Richy.
            </p>

            {!needToHideLicense && (
              <p>
                <br />

                {t('footer_company_info_1')}
              </p>
            )}

            {needToHideLicense && (
              <>
                <p>
                  <br />

                  {t('footer_company_info_1')}
                </p>
              </>
            )}

          </StyledBottomText>

          <StyledAllRights>
            {t('footer_company_info_4')}
          </StyledAllRights>
        </StyledContent>
      </StyledColoredFooterBox>
    </StyledFooterBox>
  )
}

interface LinksColumnProps {
  title: string,
  items: { label: string, href: string }[],
  hideOnDesktop?: boolean,
  alwaysOpened?: boolean
}

const LinksColumn: React.FC<LinksColumnProps> = ({ title, items, hideOnDesktop, alwaysOpened }) => {
  const { t } = useTranslation()

  const [visible, toggleVisibility] = useState(alwaysOpened || false)

  const handleToggleClick = () => {
    toggleVisibility(state => !state)
  }

  return (
    <StyledLinksColumn className={classNames({ 'hidden-on-desktop': hideOnDesktop })}>
      <StyledLinksTitle onClick={handleToggleClick}>
        {title}

        {!alwaysOpened && <StyledArrowIcon type='dropdown-arrow' />}
      </StyledLinksTitle>

      {items.map(link => (
        <Link href={link.href}
          key={link.label}
        >
          <StyledLink className={classNames({ visible })}>
            {t(link.label)}
          </StyledLink>
        </Link>
      ))}
    </StyledLinksColumn>
  )
}
