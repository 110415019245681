import { useState } from 'react'

import { createPortal } from 'react-dom'
import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'
import { useHtmlOverflow } from 'hooks/useHtmlOverflow'

import { useLayoutContext } from "../../../../../context/layoutContext"

import { SIconBtn } from './styles'
import SearchBlock from './SearchBlock'

const Search = ({ className = '' }) => {
  const { searchOpened, toggleSearch } = useLayoutContext()

  const [hover, setHover] = useState(false)
  const handleMouseMove = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  useHtmlOverflow(searchOpened)

  return (
    <>
      <SIconBtn
        onClick={() => toggleSearch(true)}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className={className}
      >
        <SvgIcon color={hover ? COLORS.white : COLORS.dark200}
          type='search'
        />
      </SIconBtn>

      {searchOpened && typeof window !== 'undefined' && createPortal(<SearchBlock setOpen={toggleSearch} />, document.body)}
    </>
  )
}

export default Search
