import React from 'react'

import { Sticky } from 'react-sticky'
import styled, { css } from 'styled-components'
import { Button, Tabs } from 'ui-kit'
import { BREAKPOINTS, COLORS, Z_INDEXES } from 'ui-kit/constants'
import { useRouter } from 'next/router'
import { Routes } from 'types/routes'
import { useAppContext } from 'context/AppContext'
import { BalancesDropdown } from 'components/layout/Header/components/Profile/BalancesDropdown/BalancesDropdown'
import { ProfileDropdown } from 'components/layout/Header/components/Profile/ProfileDropdown/ProfileDropdown'
import { LanguageDropdown } from 'components/layout/Header/components/Profile/LanguageDropdown/LanguageDropdown'
import { SvgIcon } from 'ui-kit/svg'
import { useTranslation } from 'next-i18next'
import { ModalType } from 'types/enums'
import { applyStyles, SIZES, useLayoutContext } from 'context/layoutContext'
import classNames from 'classnames'

import Search from "../../../for_pages/CatalogPage/CatalogToolbar/FiltersPanel/Search"

export const Header = ({ className = '' }) => {
  const { route: currentRoute, push } = useRouter()
  const { user, showModal } = useAppContext()
  const { t } = useTranslation()
  const { contentSize } = useLayoutContext()

  const { leftMenuOpened, toggleLeftMenu } = useLayoutContext()

  const tabs = [
    {
      content: (
        <>
          <StyledTabIcon type="home"
            className='no-margin'
          />
        </>
      ),
      value: Routes.home
    },
    {
      content: (
        <>
          <StyledTabIcon type="casino" />

          {" "}

          <StyledTabText>
            {t('header_casino')}
          </StyledTabText>
        </>
      ),
      value: Routes.catalog
    },
    {
      content: (
        <>
          <StyledTabIcon type="ball" />

          {" "}

          <StyledTabText>
            {t('header_sport')}
          </StyledTabText>
        </>
      ),
      value: Routes.sport
    },
  ]

  const handleTabChange = (value: string) => {
    push(value)
  }

  const handleSignInClick = () => {
    showModal(ModalType.login)
  }

  const handleSignUpClick = () => {
    showModal(ModalType.registration)
  }

  const handleMenuClick = () => {
    toggleLeftMenu(!leftMenuOpened)
  }

  const handleBonusesClick = () => {
    if (!!user) {
      push('/bonuses-dashboard')
    } else {
      showModal(ModalType.registration)
    }
  }

  return (
    <Sticky>
      {({ style }) => (
        <StyledHeaderBox className={className}
          style={style}
        >
          <StyledMenuBtn onClick={handleMenuClick}
            className={classNames({ active: leftMenuOpened })}
          >
            <StyledIcon type='menu' />
          </StyledMenuBtn>

          <StyledLogoImgBox href="/">
            <StyledLogoImg src="/img/logo/richy.svg" />

            {/* <StyledSmallLogoImg src={!user ? '/img/logo/richy.svg' : '/img/logo/richy_symbol.svg'} /> */}
          </StyledLogoImgBox>

          <StyledTabs
            tabs={tabs}
            activeTab={currentRoute}
            onChange={handleTabChange}
          />

          <StyledRightPart>
            <StyledSearch />

            {
              contentSize !== SIZES.MOBILE && (
                <StyledIconBtn className='gray'
                  onClick={handleBonusesClick}
                >
                  <StyledIcon type='gift' />
                </StyledIconBtn>
              )
            }

            {
              !!user && (
                <>
                  {!user.flags.isHideBalance && (
                    <StyledBalancesDropdown />
                  )}

                  <StyledProfileDropdown />

                  <StyledLanguagesDropdown />
                </>
              )
            }

            {
              !user && (
                <>
                  <StyledBtn color="gray"
                    onClick={handleSignInClick}
                  >
                    {t('header_login')}
                  </StyledBtn>

                  <StyledBtn color="green"
                    onClick={handleSignUpClick}
                  >
                    <StyledRegistrationIcon type="user-add" />

                    {t('header_registration')}
                  </StyledBtn>

                  <StyledLanguagesDropdown />
                </>
              )}
          </StyledRightPart>
        </StyledHeaderBox>
      )}
    </Sticky>
  )
}

const StyledHeaderBox = styled.div`
  width: 100vw;
  height: 64px;
  background: ${COLORS.dark750};
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: ${Z_INDEXES.header};
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 56px;
  }
`

const StyledLogoImgBox = styled.a`
  margin-top: 4px;
  width: 75px;
  height: 27px;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    height: 29px;
    margin-top: 3px;
  }
`

const StyledLogoImg = styled.img`
  width: 100%;
  height: 100%;

  /* @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    display: none
  } */
`

// const StyledSmallLogoImg = styled.img`
//   width: auto;
//   height: 100%;
//   display: none;

//   @media (max-width: ${BREAKPOINTS.xsMedium}px) {
//     display: block;
//   }
// `

const StyledTabs = styled(Tabs)`
  margin-left: 32px;
  background: ${COLORS.dark600};
  border-radius: 16px;
  padding: 2px;

  .tab {
    padding: 0 12px;
    flex: 1;
    min-height: 28px;
  }

  @media (max-width: ${BREAKPOINTS.sMin - 1}px) {
    margin-left: 20px;
  }

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    display: none;
  }
`

const StyledTabText = styled.span`
  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`

const StyledTabIcon = styled(SvgIcon)`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  min-width: 20px;

  &.no-margin {
    margin-right: 0;
  }

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-right: 0;
  }
`

const StyledRightPart = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const StyledBalancesDropdown = styled(BalancesDropdown)`
  margin-left: 8px;
`

const StyledProfileDropdown = styled(ProfileDropdown)`
  margin-left: 8px;
`

const StyledLanguagesDropdown = styled(LanguageDropdown)`
  && {
    margin-left: 8px;

    @media (max-width: ${BREAKPOINTS.sMin - 1}px) {
      display: none;
    }
  }
`

const StyledIconBtn = styled.button`
  width: 44px;
  height: 44px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;

  &.gray {
    background: ${COLORS.dark400};
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 36px;
    width: 36px;
  }

`

const StyledMenuBtn = styled(StyledIconBtn)`
  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`

const StyledIcon = styled(SvgIcon)`
  color: rgba(104, 106, 123, 1);

  ${StyledMenuBtn}:hover & {
    color: white;
  }

  ${StyledMenuBtn}.active & {
    color: white;
  }

  ${StyledIconBtn}:hover & {
    color: white;
  }

  ${StyledIconBtn}.active & {
    color: white;
  }
`

const StyledBtn = styled(Button)`
  padding: 0 25px;
  height: 44px;
  width: unset;
  margin-left: 8px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 36px;
    min-width: 42px;
    padding: 0 12px;
  }
`

const StyledRegistrationIcon = styled(SvgIcon)`
  margin-right: 2px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    margin-right: 0;
  }
`

const StyledSearch = styled(Search)`
  margin-right: 8px;
  background: ${COLORS.dark400};

  &:hover {
    background: ${COLORS.dark400};
  }

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSizeDefault, css`
      display: none;
    `)}
  `}
`
