import { LANGUAGES } from 'constants/languages'

import React, { useEffect, useMemo, useRef } from 'react'

import { FormikProvider, useFormik } from 'formik'
import styled from 'styled-components'
import { Select } from 'ui-kit'
import { useTranslation } from 'next-i18next'
import { useAppContext } from 'context/AppContext'
import { useRouter } from 'next/router'
import { FONTS } from 'ui-kit/constants'
import { Placement } from '@popperjs/core'

interface LanguageSelectProps {
  className?: string,
  placement: Placement
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({ className = '', placement = 'top' }) => {
  const { i18n } = useTranslation()
  const appContext = useAppContext()
  const router = useRouter()

  const renderedOnceRef = useRef(false)

  const currentLanguage = useMemo(
    () =>
      LANGUAGES.find((i) => i.code === i18n.language) ??
      LANGUAGES.find((i) => i.code === "en"),
    [i18n.language]
  )

  const formik = useFormik({
    initialValues: {
      language: currentLanguage?.code
    },
    onSubmit: () => undefined,
    enableReinitialize: true
  })

  useEffect(() => {
    const value = formik.values.language

    if (renderedOnceRef.current) {
      appContext.changeLanguage(value as string)

      router.push(router.asPath, router.asPath, { locale: value })
    }

    renderedOnceRef.current = true
  }, [formik.values.language])

  const languages = LANGUAGES.map(language => ({
    label: language?.name?.toUpperCase(),
    iconContent: (
      <StyledLanguageIcon src={language.icon} />
    ),
    value: language.code
  }))

  return (
    <FormikProvider value={formik}>
      <StyledSelect options={languages}
        name="language"
        placement={placement}
        className={className}
        renderToggleContent={(option) => (
          <StyledToggle>
            <StyledLanguageIcon src={LANGUAGES.find(lang => lang.code === option?.value)?.icon} />

            {option?.label}
          </StyledToggle>
        )}
      />
    </FormikProvider>
  )
}

const StyledSelect = styled(Select)`
  margin-top: 8px;

  .select-dropdown-box {
    box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, 0.25);
  }
`

const StyledLanguageIcon = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${FONTS.p1};
`
