import { LanguageSelect } from "components/layout/Header/components/Profile/LanguageSelect"
import { BallSvg } from "components/svg/BallSvg"
import { CasinoSvg } from "components/svg/CasinoSvg"
import styled from "styled-components"
import { PageOverlay, Scrollable, Tabs } from "ui-kit"
import { BREAKPOINTS, COLORS, FONTS, Z_INDEXES } from "ui-kit/constants"
import { SvgIcon } from "ui-kit/svg"

export const StyledLeftMenuBox = styled.div`
  width: 288px;
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  transform: translateX(-100%);
  background: ${COLORS.dark600};
  z-index: ${Z_INDEXES.header};
  padding: 22px 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: transform 0.2s ease-in;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);

  &.active {
    transform: translateX(0);
  }

  &.zero-top-margin {
    top: 0;
    height: calc(100vh - 50px);
  }

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-bottom: 60px;
    transition: unset;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    top: 0;
    height: 100vh;
    padding-bottom: 60px;
    overflow: auto;
  }
`

export const StyledBonuses = styled.div`
  width: 100%;
  gap: 14px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 7px;
  max-width: 380px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    width: 100%;
    gap: unset;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
  }
`

export const StyledBonusCard = styled.div`
  width: 111px;
  height: 51px;
  background: url(${(props: { img: string, activeImg?: string, order?: number }) => props.img});
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  order: ${props => props.order || 'initial'};
  position: relative;

  &.disabled {
    filter: saturate(0.1);
  }

  &:hover {
    background: url(${(props: { img: string, activeImg?: string }) => props.activeImg || props.img});
    background-size: 100% 100%;
  }

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    width: 100%;
    order: unset;
  }
`

export const StyledBonusName = styled.p`
  ${FONTS.h5};
  color: white;
  width: min-content;

  &.disabled {
    color: ${COLORS.dark200};

    ${StyledBonusCard}:hover & {
      color: #9395A8;
    }
  }
`

export const StyledBonusLock = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  position: absolute;
  left: -4px;
  top: -2px;
`

export const StyledItemsBox = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`

export const StyledDivider = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  height: 1px;
  width: 100%;
  background: #272832;
`

export const StyledItem = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  color: ${COLORS.dark80};
  cursor: pointer;
  ${FONTS.p1};
  border-radius: 9px;

  &:hover {
    background: ${COLORS.dark400};
    color: white;
  }

  &.root {
    margin-top: 10px;
    border-radius: 9px;
    background: #272832;
  }

  &.disabled {
    color: #4E4F5F;
  }

  &.separateItem {
    margin-bottom: 10px;
  }
`

export const StyledItemImg = styled.div`
  margin-right: 8px;
  width: 22px;
  height: 22px;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  &.aviator {
    margin-right: 14px;
    margin-left: -6px;
  }
`

export const StyledArrow = styled(SvgIcon)`
  margin-left: auto;
  color: #686A7B;

  &.rotated {
    transform: rotate(180deg);
  }

  &.active {
    color: white;
  }

  ${StyledItem}:hover & {
    color: white;
  }
`

export const StyledLockImg = styled.img`
  margin-left: auto;
  width: 20px;
  height: 20px;
`

export const StyledItemBigImg = styled.div`
  height: 20px;
  width: auto;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }
`

export const StyledLanguageSelect = styled(LanguageSelect)`
  width: 100%;
  border-radius: 9px;

  .select-toggle-box {
    border-radius: 9px;
  }
`

export const StyledSocials = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 26px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.xsMedium}px) {
    gap: 11px;
    justify-content: flex-start;
    padding-left: 16px;
  }
`

export const StyledSocial = styled.a`
  width: 32px;
  height: 32px;
  background: ${COLORS.dark550};
  border-radius: 4.8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #33343F;
  }
`

export const StyledSocialImg = styled.img`
  width: 16px;
  height: 16px;
`

export const StyledScrollable = styled(Scrollable)`
  margin-top: 12px;
  flex: 1;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    opacity: 0;
  }

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    padding-bottom: 110px;
  }

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    position: relative;
    right: -5px;
    width: calc(100% + 10px);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }

  @media (max-height: 599px) and (orientation: landscape) {
    height: fit-content;
    min-height: 950px;
  }
`

export const StyledInfoModalContent = styled.div`
  ${FONTS.p1};
  color: white;
`

export const StyledTabs = styled(Tabs)`
  margin-bottom: 12px;
  width: 100%;
  gap: 1%;

  .tab {
    width: 49.5%;
    border-radius: 16px;
    background: #272832;

    &.active {
      background: ${COLORS.btnBlue};
    }
  }

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`

export const StyledTabText = styled.span`
`

export const svgStyles = `
  display: flex;
  align-items: center;
  margin-right: 4px;
  width: 20px;
  height: 20px;
  min-width: 20px;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    margin-right: 0;
  }
`

export const StyledCasinoSvg = styled(CasinoSvg)`
  ${svgStyles};
`

export const StyledBallSvg = styled(BallSvg)`
  ${svgStyles};
`

export const StyledPageOverlay = styled(PageOverlay)`
  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: none;
  }
`
