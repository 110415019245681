import React from 'react'

interface Props {
  color?: string
  className?: string
}

export const SearchSvg = ({ className, color }: Props) => {
  return (
    <svg width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1039_134930)">
        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15ZM8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
          fill={color}
        />

        <path fillRule="evenodd"
          clipRule="evenodd"
          d="M18.293 18.7071C17.9025 19.0976 17.2694 19.0976 16.8788 18.7071L13.293 15.1213C12.9025 14.7308 12.9025 14.0976 13.293 13.7071C13.6836 13.3166 14.3167 13.3166 14.7073 13.7071L18.293 17.2929C18.6836 17.6834 18.6836 18.3166 18.293 18.7071Z"
          fill={color}
        />
      </g>

      <defs>
        <clipPath id="clip0_1039_134930">
          <rect width="19"
            height="19"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
