import styled, { css } from 'styled-components'
import { COLORS, Z_INDEXES } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'
import { Button, Input } from 'ui-kit'


export const SSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 64px);
  left: 0;
  right: 0;
  bottom: 0;
  top: 64px;
  z-index: 1000;
  background: ${COLORS.dark600};
  z-index: ${Z_INDEXES.header - 1};

  &.content-width {
    width: calc(100vw - 288px);
    left: unset;
  }

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSizeDefault, css`
      padding: 12px;
      height: calc(100vh - 56px);
      top: 56px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSizeDefault, css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}

    ${applyStyles([SIZES.W1040], theme.contentSizeDefault, css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    `)}
  `}
`

export const SProvidersContent = styled.div`
  display: flex;
  padding-top: 16px;
  flex-direction: column;

  ${({ theme }) => `
    ${theme.contentSizeDefault === SIZES.MOBILE ? 'width: 100vw;' : `width: ${theme.contentSizeDefault}px;`}

    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSizeDefault, `
      padding-top: 0;
    `)}
  `}
`

export const SInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const SSearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 2;
`

export const SCloseBtn = styled.button`
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 10px;
  padding: 0;
  z-index: 2;
`

export const SPanel = styled.div`
  display: flex;
  gap: 12px
`

export const SInput = styled(Input)`
  position: sticky;
  width: 100%;
  top: 0;


  .input-box {
    padding: 0 36px;

    &.focused {
      border-radius: 4px;
    }
  }

`

export const SSearchPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
`

export const SScrollList = styled.div`
  margin-top: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 156px);
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSizeDefault, css`
      padding-bottom: 120px;
    `)}
  `}
`

export const SGamesList = styled.div`
  display: grid;
  grid-gap: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => `
    ${applyStyles([SIZES.MOBILE, SIZES.W512], theme.contentSizeDefault, css`
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8px;
  `)}

    ${applyStyles([SIZES.W688], theme.contentSizeDefault, css`
      grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}

    ${applyStyles([SIZES.W1040], theme.contentSizeDefault, css`
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    `)}
  `}
`

export const SItem = styled.div`
  background: ${COLORS.dark550};
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
  min-height: 90px;
  box-sizing: border-box;

  &:hover {
    background: ${COLORS.dark500};
  }
`

export const SLoadMore = styled.div`
  display: flex;
  margin: 24px auto 0;
  align-items: center;
`

export const SLoadButton = styled(Button)`
  padding: 12px;
  margin: auto;
  box-sizing: border-box;
  width: 184px;
`
