import styled, { css } from 'styled-components'
import { COLORS, FONTS } from 'ui-kit/constants'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SFiltersPanel = styled.div<{ showFavorites: boolean }>`
  display: grid;
  gap: 8px;

  ${({ theme, showFavorites }) => `
    ${applyStyles([SIZES.MOBILE], theme.contentSize, `
      grid-template-columns: 1fr 40px 40px ${showFavorites ? '40px' : '' };
      margin: 0 12px;
    `)}

    ${applyStyles([SIZES.W512], theme.contentSize, `
      grid-template-columns: 1fr 40px 40px ${showFavorites ? '40px' : '' };
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, `
      grid-template-columns: 1fr 44px 44px ${showFavorites ? '44px' : '' };
    `)}

    ${applyStyles([SIZES.W1040], theme.contentSize, `
      grid-template-columns: 1fr 44px 44px ${showFavorites ? '44px' : '' };
    `)}
  `};
`

export const SPanelBtn = styled.button<{ color?: string, size: number }>`
  background: ${({ color }) => color};
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex: none;
  transition: 0.2s;
  box-sizing: border-box;
  padding: 6px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;

  &:hover {
    background: ${COLORS.dark550};
  }

  ${({ theme, size }) => `
    ${applyStyles([SIZES.W512, SIZES.MOBILE], theme.contentSize, `
      min-width: ${size || 40}px;
      height: ${size || 40}px;
    `)}
  `}
`

export const SBtnLabel = styled.div`
  color: ${COLORS.dark200};
  ${FONTS.p1};
  flex: 1;
  display: flex;
  align-items: center;
`

export const SDropdownArrow = styled.div`
  flex: 1;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`

export const SProvidersBtn = styled(SPanelBtn)`
  background: ${COLORS.dark600};
  justify-content: flex-start;
  padding: 6px 8px 6px 12px;
  gap: 4px;
  align-items: center;

  &:hover ${SBtnLabel} {
    color: ${COLORS.white};
  }
`

export const SIconBtn = styled(SPanelBtn)`
  background: ${COLORS.dark600};
  justify-content: flex-start;
  flex: none;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;
  width: 44px;
  height: 44px;

  ${({ theme }) => `
    ${applyStyles([SIZES.W512, SIZES.MOBILE], theme.contentSize, css`
      width: 40px;
      height: 40px;
    `)}

    ${applyStyles([SIZES.W688], theme.contentSize, css`
      padding: 6px;
    `)}
  `};
`

