import { FC } from 'react'

import { SContentPage, SContentPageWrapper } from './styles'


const ContentPage: FC = ({ children, custom, loading  }) => {
  return (
    <>
      {!!custom && !loading ? custom : ''}

      <SContentPageWrapper>
        <SContentPage>
          {children}
        </SContentPage>
      </SContentPageWrapper>
    </>
  )
}

export default ContentPage
