import { FC, useState } from 'react'

import { useRouter } from 'next/router'
import { COLORS } from 'ui-kit/constants'
import { useAppContext } from 'context/AppContext'
import { SvgIcon } from 'ui-kit/svg'

import { SPanelBtn } from './styles'

const Favorites: FC<{ color?: string, size?: number }> = ({ color, size= 40 }) => {
  const [hover, setHover] = useState(false)
  const handleMouseMove = () => setHover(true)
  const handleMouseLeave = () => setHover(false)
  const { auth } = useAppContext()

  if (!auth) return null

  const router = useRouter()
  const handleGoToFavorites = () => router.push('/catalog/category/favorite')

  return (
    <SPanelBtn onClick={handleGoToFavorites}
      color={color || COLORS.dark600}
      size={size}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <SvgIcon color={hover ? COLORS.white : COLORS.dark200}
        type='star-full'
      />
    </SPanelBtn>
  )
}

export default Favorites
