import { useEffect } from "react"

export const useHtmlOverflow = (isOverflowHidden: boolean) => {
  useEffect(() => {
    if (isOverflowHidden) {
      (document.querySelector('html') as HTMLElement).style.overflow = 'hidden'
    } else {
      (document.querySelector('html') as HTMLElement).style.overflow = 'auto'
    }
  }, [isOverflowHidden])

  useEffect(() => {
    return () => {
      (document.querySelector('html') as HTMLElement).style.overflow = 'auto'
    }
  }, [])
}
