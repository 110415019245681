import { LANGUAGES } from 'constants/languages'

import React, { useMemo, useRef } from 'react'

import styled from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { useAppContext } from 'context/AppContext'
import { useTranslation } from 'next-i18next'
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { SvgIcon } from 'ui-kit/svg'
import { DEFAULT_LANGUAGE } from 'types/language'
import { useRouter } from 'next/router'
import Link from 'next/link'
import classNames from 'classnames'

export const LanguageDropdown = ({ className = '' }) => {
  const appContext = useAppContext()
  const { i18n } = useTranslation()
  const router = useRouter()
  const dropdownRef = useRef(null)

  const [opened, toggleDropdown] = useDetectOutsideClick(dropdownRef, false)

  const items = LANGUAGES

  const currentLanguage = useMemo(
    () =>
      items.find((i) => i.code === i18n.language) ??
      items.find((i) => i.code === "en"),
    [i18n.language]
  )

  const handleSelect = async (code: string) => {
    toggleDropdown(false)
    appContext.changeLanguage(code)
  }

  return (
    <LanguageDropdownBox className={className}
      ref={dropdownRef}
    >
      <DropdownToggle onClick={() => toggleDropdown(!opened)} className='languages-dropdown-toggle'>
        <LanguageImg src={currentLanguage?.icon} />

        <LangShortName>
          {currentLanguage?.name}
        </LangShortName>

        <DropdownArrow type="dropdown-arrow"
          className={classNames({ rotated: opened })}
        />
      </DropdownToggle>

      <DropdownBox className={classNames({ opened })}>
        <DropdownContent>
          {items.map(item => (
            <Link
              key={item.icon}
              locale={item.code}
              href={
                item.code === DEFAULT_LANGUAGE
                  ? router.asPath
                  : `/${item.code}${router.asPath}`
              }
            >
              <LanguageBox
                onClick={() => handleSelect(item.code)}
                className={classNames({ active: currentLanguage?.code === item.code })}
              >
                <LanguageImg src={item.icon} />

                {item.name}
              </LanguageBox>
            </Link>
          ))}
        </DropdownContent>
      </DropdownBox>
    </LanguageDropdownBox>
  )
}

const LanguageDropdownBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 44px;

  @media (max-width: ${BREAKPOINTS.xsMedium - 1}px) {
    height: 32px;
  }
`

const DropdownToggle = styled.div`
  height: 100%;
  background: ${COLORS.dark400};
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  user-select: none;
`

const DropdownArrow = styled(SvgIcon)`
  margin-left: 8px;
  color: ${COLORS.dark200};
  transition: 0.2s;

  &.rotated {
    transform: rotate(180deg);
    color: white;
  }
`

const LangShortName = styled.span`
  margin-left: 3px;
  ${FONTS.a1};
  color: white;
  text-transform: uppercase;
`

const DropdownBox = styled.div`
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.dark600};
  border-radius: 0px 0px 12px 12px;
  position: absolute;
  top: 54px;
  right: 0;
  width: 414px;
  display: none;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);

  &.opened {
    display: block;
  }

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    right: 0;
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 60px;
    left: 0;
    z-index: 10000;
    transform: unset;
  }
`

const DropdownContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
  }
`

const LanguageImg = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const LanguageBox = styled.div`
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  ${FONTS.p1};
  color: ${COLORS.dark200};
  cursor: pointer;
  border-radius: 4px;
  text-transform: uppercase;

  &.active {
    background: ${COLORS.dark400};
    color: white;
  }

  &:hover {
    background: ${COLORS.dark400};
    color: white;
  }
`
