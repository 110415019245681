import { IPagination } from 'types/interfaces'
import { IGame } from 'data/interfaces/IGame'
import request from 'utils/request'
import Converter from 'utils/converter'
import { IGameProvider } from 'data/interfaces/IGameProvider'
import { IGameHistory } from 'data/interfaces/IGameHistory'
import { ITournamentHistory } from 'data/interfaces/ITournamentHistory'

export interface IGetGamesArgs {
    name?: string;
    providerId?: number;
    categoryId?: number | null;
    providerInternalName?: string;
    isShowAll?: boolean;
    isMobile?: boolean;
    limit?: number;
    page?: number;
}

export const fetchGames = async (
  {
    name,
    providerId,
    categoryId,
    providerInternalName,
    isShowAll,
    isMobile,
    limit = 24,
    page = 1,
  }: IGetGamesArgs = {},
): Promise<IPagination<IGame>> => {
  console.time(`/api/games/game ${JSON.stringify({ providerId, name, categoryId, providerInternalName, limit, page })}`)
  const res = await request({
    method: 'get',
    url: '/api/games/game',
    data: {
      client_type: isMobile ? 'mobile' : 'desktop',
      ...(name ? { name } : {}),
      ...(providerId ? { provider_id: providerId } : {}),
      ...(isShowAll ? { is_show_all: true } : {}),
      ...(categoryId ? { category_id: categoryId } : {}),
      ...(providerInternalName ? { providerInternalName } : {}),
      page,
      'per-page': limit,
    },
  })
  console.timeEnd(`/api/games/game ${JSON.stringify({ providerId, name, categoryId, providerInternalName, limit, page })}`)
  if (res.err) {
    return { data: [], total: 0 }
  }

  return Converter.convertApiPaginationResponse(res.data)
}


export async function fetchTopGames(
  isMobile?: boolean | 'mobile' | 'desktop'
): Promise<IPagination<IGame>> {
  const res = await request({
    method: 'get',
    url: '/api/games/game/top',
    data: {
      client_type: isMobile ? 'mobile' : 'desktop',
    },
  })

  if (res.err) {
    return { data: [], total: 0 }
  }

  return Converter.convertApiPaginationResponse(res.data)
}


export const fetchProviders = async ({ name }): Promise<{ data: IGameProvider[], total: number, totalPages: number, name: string }> => {
  console.time('/api/games/provider/index')
  const res = await request({
    method: 'get',
    url: '/api/games/provider/index',
    data: {
      ...(name ? { name } : {}),
    }
  })
  console.timeEnd('/api/games/provider/index')

  if (res.err) {
    return {
      data: [],
      total: 0,
      totalPages: 0
    }
  }

  return Converter.convertApiPaginationResponse(res.data)
}


export async function fetchLiveGames(
  {
    name,
    providerId,
    categoryId,
    isMobile,
    page = 1,
    limit = 24
  }: { name?: string; providerId?: number; categoryId?: number; isMobile?: boolean, page?: number, limit?: number } = {},
): Promise<IPagination<IGame>> {
  console.time('/api/games/game/live')
  const res = await request({
    method: 'get',
    url: '/api/games/game/live',
    data: {
      client_type: isMobile ? 'mobile' : 'desktop',
      ...(name ? { name } : {}),
      ...(providerId ? { provider_id: providerId } : {}),
      ...(categoryId ? { category_id: categoryId } : {}),
      page,
      'per-page': limit,
    },
  })
  console.timeEnd('/api/games/game/live')

  if (res.err) {
    return { data: [], total: 0 }
  }

  return Converter.convertApiPaginationResponse(res.data)
}


export async function fetchGameSessionHistoryLastRows(
  rowAmount: number
): Promise<IPagination<IGameHistory>> {
  console.time('/api/games/session/history-all')
  const res = await request({
    method: 'get',
    url: '/api/games/session/history-all',
    data: {
      row_amount: rowAmount,
    },
  })
  console.timeEnd('/api/games/session/history-all')
  if (res.err) {
    return { data: [], total: 0 }
  }

  return Converter.convertApiPaginationResponse(res.data)
}


interface IApiResponse<T> {
    err?: string;
    data?: T;
}

interface IRequestResponse<T> {
    data: T;
}

export async function fetchTournamentHistory(
  status: number | null,
  language?: string,
  page = 1,
  limit = 1000
): Promise<IPagination<ITournamentHistory> | null> {
  const requestData = {
    ...(status ? { status } : {}),
    page,
    'per-page': limit,
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const res: IApiResponse<IRequestResponse<IPagination<ITournamentHistory>>> = await request({
    method: 'get',
    url: '/api/tournament/round/history',
    language,
    data: requestData,
  })

  if (res.err) {
    return { data: [], total: 0 }
  }

  return Converter.convertApiPaginationResponse(res.data) || { data: [], total: 0 }
}
