import styled from 'styled-components'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SContentPageWrapper = styled.div`
  width: 100vw;

  ${({ theme }) => applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, `
    ${(theme.leftMenuOpened && theme.isNewPage) && `
      width: calc(100vw - 277px);
      transform: translateX(277px);
    `}
  `)}
`

export const SContentPage = styled.div`
  max-width: 100vw;
  margin: auto;

  ${({ theme }) => (
    theme.contentSize !== SIZES.MOBILE && theme.isNewPage ? `width: ${theme.contentSize}px;` : '100vw'
  )}
`
