import React, { FC, useMemo, useState } from 'react'

import { useFormik, FormikProvider } from 'formik'
import Image from 'next/image'
import { SvgIcon } from 'ui-kit/svg'
import { COLORS } from 'ui-kit/constants'
import { useInfiniteQuery } from '@tanstack/react-query'
import { fetchGames } from 'components/for_pages/HomePage/requests'
import GameCard from 'components/pages-blocks/Components/GameCard'
import { useAppContext } from 'context/AppContext'
import { useTranslation } from 'next-i18next'
import { SearchSvg } from 'components/svg/SearchSvg'
import classNames from "classnames"
import { useDebounce } from "usehooks-ts"

import Favorites from '../Favorites'
import { useLayoutContext } from "../../../../../../context/layoutContext"

import {
  SProvidersContent, SSearchWrapper, SInput, SInputWrapper, SCloseBtn, SGamesList, SPanel, SSearchPlaceholder,
  SLoadButton, SLoadMore, SScrollList, SSearchIcon
} from './styles'


const SearchBlock: FC<{ setOpen: (cstate: boolean) => void }> = ({ setOpen }) => {
  const { isMobile } = useAppContext()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: { search: '' },
    onSubmit: () => undefined
  })
  const [isInputFocused, setInputFocused] = useState(false)
  const layoutContext = useLayoutContext()

  const handleInputFocus = () => setInputFocused(true)
  const handleInputBlur = () => setInputFocused(false)

  const searchValue = useDebounce(formik?.values?.search, 500)

  const { data, isFetching, fetchNextPage } = useInfiniteQuery(
    ['fetchSearchGames', searchValue, isMobile],
    ({ pageParam }) => fetchGames({ name: searchValue || '', isMobile, limit: 30, page: pageParam?.page || 1 }),
    {
      enabled: !!searchValue,
      getNextPageParam: (lastPage, pages) => ({ page: pages.length + 1 }),
    }
  )

  const { gamesList, showLoadMore } = useMemo(() => {
    const fetchedGames = data?.pages?.reduce((result, current) => result.concat(current?.data), []) || []

    return {
      gamesList: fetchedGames,
      showLoadMore: data?.pages ? data?.pages?.[data?.pages.length - 1].total > fetchedGames.length : false
    }
  },[data])

  const handleCloseBlock = () => setOpen(false)

  return (
    <SSearchWrapper className={classNames({ 'content-width': layoutContext.leftMenuOpened })}>
      <SProvidersContent>
        <SPanel>
          <SInputWrapper>
            <SCloseBtn onClick={handleCloseBlock}>
              <SvgIcon type='close'
                color={COLORS.dark200}
              />
            </SCloseBtn>

            <SSearchIcon>
              <SearchSvg color={isInputFocused ? 'white' : '#686A7B'} />
            </SSearchIcon>

            <FormikProvider value={formik}>
              <SInput
                name='search'
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </FormikProvider>
          </SInputWrapper>

          <Favorites color={COLORS.dark550}
            size={44}
          />
        </SPanel>

        {!!formik?.values?.search ? (
          <>
            <SScrollList>
              <SGamesList>
                {gamesList?.map((game) => (
                  <GameCard key={game.id}
                    game={game}
                    withoutMargin
                  />
                ))}
              </SGamesList>

              {showLoadMore && (
                <SLoadMore>
                  <SLoadButton
                    color='gray'
                    onClick={fetchNextPage}
                    spinner={isFetching}
                    disabled={isFetching}
                  >
                    {t('catalog_list_more')}
                  </SLoadButton>
                </SLoadMore>
              )}
            </SScrollList>
          </>
        ) : (
          <SSearchPlaceholder>
            <Image
              src='/img/search/search_img.png'
              height={196}
              width={217}
            />
          </SSearchPlaceholder>
        )}
      </SProvidersContent>
    </SSearchWrapper>
  )
}

export default SearchBlock
