import React, { useRef } from 'react'

import styled from 'styled-components'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { ProfileModalType } from 'types/enums'
import { useAppContext } from 'context/AppContext'
import { useTranslation } from 'next-i18next'
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { SvgIcon } from 'ui-kit/svg'
import { useRouter } from 'next/router'

enum ActionType{
  Profile,
  BonusDashboard,
  AddMoney,
  Payout,
  Transactions,
  BetsHistory,
  Referral,
  Settings,
  Wallet,
  Support,
  Logout
}

export const ProfileDropdown = ({ className = '' }) => {
  const appContext = useAppContext()
  const { showModalProfile, logout } = appContext
  const { t } = useTranslation()
  const router = useRouter()
  const dropdownRef = useRef(null)

  const [opened, toggleDropdown] = useDetectOutsideClick(dropdownRef, false)

  const options = [
    { label: t('header_profile_menu_profile'), key: ActionType.Profile, icon: 'profile' },
    { label: t('header_profile_menu_deposit'),  key: ActionType.AddMoney, icon: 'wallet' },
    { label: t('header_profile_menu_bonus_dashboard'),  key: ActionType.BonusDashboard, icon: 'wallet' },
    { label: t('header_profile_menu_bets_history'), key: ActionType.BetsHistory, icon: 'transaction-history' },
    { label: t('header_profile_menu_transactions'), key: ActionType.Transactions, icon: 'betting-history' },
    { label: t('header_profile_menu_withdrawal'), key: ActionType.Payout, icon: 'withdrawal' },
  ]

  const handleOptionClick = (key) => {
    switch (key) {
    case ActionType.Profile:
      router.push('/account/profile')
      break
    case ActionType.AddMoney:
      showModalProfile(ProfileModalType.wallet)
      break
    case ActionType.BonusDashboard:
      router.push('/bonuses-dashboard')
      break
    case ActionType.Payout:
      appContext.setWalletModalActiveTab('withdraw')
      showModalProfile(ProfileModalType.wallet)
      break
    case ActionType.Transactions:
      router.push('/account/transactions')
      break
    case ActionType.BetsHistory:
      showModalProfile(ProfileModalType.betsHistory)
      break
    case ActionType.Referral:
      // TODO add link
      break
    case ActionType.Settings:
      showModalProfile(ProfileModalType.settings)
      break
    case ActionType.Wallet:
      router.push('/account/wallet')
      break
    case ActionType.Support:
      appContext.openSupport()
      break
    case ActionType.Logout:
      logout()
      break
    }

    toggleDropdown(false)
  }

  return (
    <ProfileDropdownBox className={className}
      ref={dropdownRef}
    >
      <DropdownToggle onClick={() => toggleDropdown(!opened)}>
        <CharImg src="/img/Profile/char.png"
          className='hide-on-mobile'
        />

        <CharMobileImg src="/img/Profile/char-mobile.png" />
      </DropdownToggle>

      {
        opened && (
          <DropdownBox>
            <DropdownContent>
              <TopRow>
                <Avatar>
                  <CharImg src="/img/Profile/char.png" />
                </Avatar>

                <UserInfo>
                  <UserName>
                    {appContext.user.username}
                  </UserName>

                  <UserId>
                    ID:
                    {' '}

                    {appContext.user.id}
                  </UserId>
                </UserInfo>

                <SettingsBtn onClick={() => handleOptionClick(ActionType.Settings)}>
                  <StyledSettingsIcon type="settings" />
                </SettingsBtn>
              </TopRow>
            </DropdownContent>

            <OptionsBox>
              {
                options.map(option => (
                  <Option key={option.label}
                    onClick={() => handleOptionClick(option.key)}
                  >
                    <StyledSvgIcon type={option.icon} />

                    {option.label}
                  </Option>
                ))
              }
            </OptionsBox>

            <OptionsBox>
              <Option onClick={() => handleOptionClick(ActionType.Support)}>
                <StyledSvgIcon type="support" />

                {t('header_profile_menu_support')}
              </Option>
            </OptionsBox>

            <Option className='no-hover'
              onClick={() => handleOptionClick(ActionType.Logout)}
            >
              <StyledSvgIcon type="signout" />

              {t('header_profile_menu_logout')}
            </Option>
          </DropdownBox>
        )
      }

    </ProfileDropdownBox>
  )
}

const ProfileDropdownBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 44px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    height: 36px;
  }
`

const DropdownToggle = styled.div`
  height: 100%;
  width: 44px;
  background: linear-gradient(180deg, #42C126 0%, #33343F 84.90%);
  position: relative;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 36px;
  }
`

const CharImg = styled.img`
  width: 30px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 6.5px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    &.hide-on-mobile {
      display: none;
    }

  }
`

const CharMobileImg = styled.img`
  height: auto;
  position: absolute;
  bottom: 0;
  width: 26px;
  left: 5px;
  display: none;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: block;
  }
`

const DropdownBox = styled.div`
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  background: ${COLORS.dark600};
  border-radius: 0px 0px 12px 12px;
  position: absolute;
  top: 54px;
  right: -50px;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  max-height: calc(100vh - 100px);
  overscroll-behavior: contain;

  @media (max-width: ${BREAKPOINTS.xsMax}px) {
    right: 0;
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 56px;
    left: 0;
    z-index: 10000;
    transform: unset;
  }

  @media (max-height: 599px) and (orientation: landscape) {
    width: 400px;
    position: fixed;
    overflow: auto;
    top: 56px;
    right: 0;
    left: unset;
    padding-bottom: 40px;
    z-index: 10000;
    transform: unset;
    height: calc(100vh - 56px - 52px);
  }
`

const DropdownContent = styled.div`
  width: 312px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
  }
`

const TopRow = styled.div`
  display: flex;
`

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  background: linear-gradient(180deg, #42C126 0%, #33343F 84.90%);
  position: relative;
  border-radius: 4px;
`

const UserInfo = styled.div`
  margin-left: 8px;
`

const UserName = styled.p`
  ${FONTS.a1};
  color: white;
`

const UserId = styled.p`
  margin-top: 4px;
  color: ${COLORS.dark200};
  ${FONTS.a2};
`

const SettingsBtn = styled.div`
  margin-left: auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.dark400};
  border-radius: 4px;
  cursor: pointer;
`


const StyledSettingsIcon = styled(SvgIcon)`
  width: 20px;
  height: 20px;
  color: ${COLORS.dark80};

  ${SettingsBtn}:hover & {
    color: white;
  }
`

const OptionsBox = styled.div`
  margin-top: 16px;
  border-radius: 4px;
  background: ${COLORS.dark550};

  & ~ & {
    margin-top: 12px;
  }
`

const Option = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  color: ${COLORS.dark80};
  ${FONTS.p1};
  line-height: 1;

  &:hover {
    background: ${COLORS.dark400};
    color: white;
  }

  &.no-hover {
    &:hover {
      background: initial;
      color: ${COLORS.dark80};
    }
  }
`

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 8px;
  color: ${COLORS.dark80};
  width: 20px;
  height: 20px;

  ${Option}:hover & {
    color: white;
  }

  ${Option}.no-hover:hover & {
    color: ${COLORS.dark80};
  }
`
