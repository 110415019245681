import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { COLORS } from 'ui-kit/constants'
import { useTranslation } from 'next-i18next'
import { InfoModal } from 'ui-kit'
import { SIZES, useLayoutContext } from 'context/layoutContext'
import { CONTACTS } from 'utils'
import { useRouter } from 'next/router'
import { Routes } from 'types/routes'
import { useAppContext } from 'context/AppContext'
import Image from 'next/image'

import {
  StyledArrow,
  StyledBallSvg,
  StyledBonusCard,
  StyledBonusLock,
  StyledBonusName,
  StyledBonuses,
  StyledCasinoSvg,
  StyledDivider,
  StyledInfoModalContent,
  StyledItem,
  StyledItemBigImg,
  StyledItemImg,
  StyledItemsBox,
  StyledLanguageSelect,
  StyledLeftMenuBox,
  StyledLockImg,
  StyledPageOverlay,
  StyledScrollable,
  StyledSocial,
  StyledSocialImg,
  StyledSocials,
  StyledTabText,
  StyledTabs
} from './styles'

export const LeftMenu = ({ className = '', zeroTopMargin = false }) => {
  const { t } = useTranslation()
  const { isMobile } = useAppContext()
  const router = useRouter()
  const appContext = useAppContext()

  const { leftMenuOpened, toggleLeftMenu, contentSize, isNewPage } = useLayoutContext()
  const [infoModalOpened, toggleInfoModal] = useState(false)

  const [casinoDropdownOpened, toggleCasinoDropdown] = useState(false)
  const [sportDropdownOpened, toggleSportDropdown] = useState(false)

  const handleCasinoToggleClick = () => {
    toggleCasinoDropdown(state => !state)
  }

  const handleSportToggleClick = () => {
    toggleSportDropdown(state => !state)
  }

  const handleDisabledSectionClick = () => {
    toggleInfoModal(true)
  }

  useEffect(() => {
    if (router.pathname.startsWith('/catalog')) {
      toggleCasinoDropdown(true)
      toggleSportDropdown(false)
    }

    if (router.pathname.startsWith('/sports')) {
      toggleCasinoDropdown(false)
      toggleSportDropdown(true)
    }
  }, [router.pathname])

  const tabs = [
    {
      content: (
        <>
          <StyledCasinoSvg
            color={
              router.route === Routes.catalog ? "white" : COLORS.dark200
            }
          />

          {" "}

          <StyledTabText>
            {t('left_menu_casino')}
          </StyledTabText>
        </>
      ),
      value: Routes.catalog
    },
    {
      content: (
        <>
          <StyledBallSvg
            color={
              router.route === Routes.sport ? "white" : COLORS.dark200
            }
          />

          {" "}

          <StyledTabText>
            {t('left_menu_sport')}
          </StyledTabText>
        </>
      ),
      value: Routes.sport
    },
  ]

  const bonuses = [
    {
      name: t('left_menu_bonus'),
      img: '/img/left-menu/bonus.png',
      activeImg: '/img/left-menu/bonus-active.png',
      order: 1,
      onClick: () => router.push(Routes.bonuses)
    },
    {
      name: t('left_menu_rakeback'),
      img: '/img/left-menu/rakeback.png',
      activeImg: '/img/left-menu/rakeback-active.png',
      disabled: true,
      order: 4,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_cashback'),
      img: '/img/left-menu/cashback.png',
      activeImg: '/img/left-menu/cashback-active.png',
      disabled: true,
      order: 3,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_fortune_wheel'),
      img: '/img/left-menu/wheel.png',
      activeImg: '/img/left-menu/wheel-active.png',
      disabled: true,
      order: 2,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_tasks'),
      img: '/img/left-menu/tasks.png',
      activeImg: '/img/left-menu/tasks-active.png',
      disabled: true,
      order: 6,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_free_crypto'),
      img: '/img/left-menu/free-crypto.png',
      activeImg: '/img/left-menu/free-crypto-active.png',
      disabled: true,
      order: 5,
      onClick: handleDisabledSectionClick
    },
  ]

  const menuItemsTop = [
    {
      name: t('left_menu_richy_club'),
      img: '/img/left-menu/items/club.svg',
      disabled: false,
      onClick: () => router?.push(Routes.loyaltyProgram)
    },
    {
      img: '/img/left-menu/items/aviator.png',
      bigImg: '/img/left-menu/items/aviator-big.png',
      disabled: false,
      isAviator: true,
      onClick: () => router.push(isMobile ? Routes.aviatorMobile : Routes.aviator)
    }
  ]

  const menuItemsCasino = [
    {
      name: t('left_menu_casino'),
      img: '/img/left-menu/items/casino.png',
      items: [
        {
          name: t('left_menu_slots'),
          img: '/img/left-menu/slots.png',
          onClick: () => router.push(Routes.catalogCategory(4))
        },
        {
          name: t('left_menu_live_casino'),
          img: '/img/left-menu/items/live.png',
          onClick: () => router.push(Routes.catalogLive)
        },
        {
          name: t('left_menu_cards'),
          img: '/img/left-menu/items/table.png',
          onClick: () => router.push(Routes.catalogCategory(2))
        },
        {
          name: t('left_menu_dice'),
          img: '/img/left-menu/items/dice.png',
          onClick: () => router.push(Routes.catalogCategory(7))
        },
        {
          name: t('left_menu_crash'),
          img: '/img/left-menu/items/crash.png',
          onClick: () => router.push(Routes.catalogCategory(10))
        },
        {
          name: t('left_menu_fast_games'),
          img: '/img/left-menu/items/fast.png',
          onClick: () => router.push(Routes.catalogCategory(5))
        },
        {
          name: t('left_menu_plinko'),
          img: '/img/left-menu/items/plinko.png',
          onClick: () => router.push(Routes.catalogCategory(23))
        },
      ]
    },
  ]

  const menuItemsSport = [
    {
      name: t('left_menu_sport'),
      img: '/img/left-menu/items/sport.png',
      items: [
        {
          name: t('left_menu_live'),
          img: '/img/left-menu/items/sport-live.png',
          onClick: () => router.push('/sports?bt-path=%2Flive')
        },
        {
          name: t('left_menu_soccer'),
          img: '/img/left-menu/items/sport.png',
          onClick: () => router.push('/sports?bt-path=%2Fsoccer-1')
        },
        {
          name: t('left_menu_cricket'),
          img: '/img/left-menu/items/cricket.png',
          onClick: () => router.push('/sports?bt-path=%2Fcricket-21')
        },
        {
          name: t('left_menu_esports'),
          img: '/img/left-menu/items/esports.png',
          onClick: () => router.push('/sports?bt-path=%2Fundefined-e_sport')
        },
        {
          name: t('left_menu_hockey'),
          img: '/img/left-menu/items/cricket.png',
          onClick: () => router.push('/sports?bt-path=%2Fice-hockey-4')
        },
        {
          name: t('left_menu_tennis'),
          img: '/img/left-menu/items/tennis.png',
          onClick: () => router.push('/sports?bt-path=%2Ftennis-5')
        },
        {
          name: t('left_menu_ecricket'),
          img: '/img/left-menu/items/cricket.png',
          onClick: () => router.push('/sports?bt-path=%2Fecricket-305')
        },
        {
          name: t('left_menu_efifa'),
          img: '/img/left-menu/items/sport.png',
          onClick: () => router.push('/sports?bt-path=%2Ffifa-300')
        },
      ]
    },
  ]

  const menuItemsBottom = [
    {
      name: t('left_menu_lottery'),
      img: '/img/left-menu/items/lottery.png',
      onClick: () => router.push(Routes.lottery)
    },
    {
      name: t('left_menu_chess'),
      img: '/img/left-menu/items/chess.png',
      onClick: () => router.push(Routes.chess)
    },
    {
      name: t('left_menu_bonus_info'),
      img: '/img/left-menu/items/bonus.png',
      onClick: () => router.push(Routes.bonuses)
    },
    {
      name: t('left_menu_tournaments'),
      img: '/img/left-menu/items/tournaments.png',
      disabled: true,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_shop'),
      img: '/img/left-menu/items/shop.png',
      disabled: true,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_tasks'),
      img: '/img/left-menu/items/tasks.png',
      disabled: true,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_loot_box'),
      img: '/img/left-menu/items/loot-box.png',
      disabled: true,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_referral'),
      img: '/img/left-menu/items/referral.png',
      disabled: true,
      onClick: handleDisabledSectionClick
    },
    {
      name: t('left_menu_faq'),
      img: '/img/left-menu/items/faq.svg',
      onClick: () => router.push(Routes.termsAndConditions)
    },
  ]

  const socials = [
    {
      icon: '/img/left-menu/socials/tg.svg',
      link: CONTACTS.telegram
    },
    {
      icon: '/img/left-menu/socials/google.svg',
      link: CONTACTS.email
    },
    {
      icon: '/img/left-menu/socials/inst.svg',
      link: CONTACTS.instagram
    },
    {
      icon: '/img/left-menu/socials/fb.svg',
      link: CONTACTS.facebook
    },
  ]

  const handleItemClick = (item: any) => {
    if (item.onClick !== handleDisabledSectionClick) {
      toggleLeftMenu(false)
    }

    if (item.onClick) {
      item.onClick()
    }
  }

  const handleToggleLeftMenu = () => {
    toggleLeftMenu(!leftMenuOpened)
  }

  const handleSupportClick = () => {
    toggleLeftMenu(false)
    appContext.openSupport()
  }

  const handleCloseInfoModal = () => {
    toggleInfoModal(false)
  }

  const handleTabChange = (value: string) => {
    router.push(value)
    toggleLeftMenu(false)
  }

  return (
    <>
      <StyledLeftMenuBox className={classNames(className, { active: leftMenuOpened, 'zero-top-margin': zeroTopMargin })}>
        <StyledTabs
          tabs={tabs}
          activeTab={router.route}
          onChange={handleTabChange}
        />

        <StyledBonuses>
          {bonuses.map(bonus => (
            <StyledBonusCard
              key={bonus.name}
              img={bonus.img}
              activeImg={bonus.activeImg}
              className={classNames({ disabled: bonus.disabled })}
              order={bonus.order}
              onClick={() => handleItemClick(bonus)}
            >
              <StyledBonusName className={classNames({ disabled: bonus.disabled })}>
                {bonus.name}
              </StyledBonusName>

              {bonus.disabled && <StyledBonusLock src="/img/left-menu/lock.svg" />}
            </StyledBonusCard>
          ))}
        </StyledBonuses>

        <StyledScrollable>
          <StyledItemsBox>
            <MenuItems
              items={menuItemsTop}
              onItemClick={handleItemClick}
            />

            <MenuItems
              items={menuItemsCasino}
              onItemClick={handleItemClick}
              opened={casinoDropdownOpened}
              onToggleClick={handleCasinoToggleClick}
            />

            <MenuItems
              items={menuItemsSport}
              onItemClick={handleItemClick}
              opened={sportDropdownOpened}
              onToggleClick={handleSportToggleClick}
            />

            <StyledDivider />

            <MenuItems
              items={menuItemsBottom}
              onItemClick={handleItemClick}
              opened
            />
          </StyledItemsBox>

          <StyledDivider />

          <StyledItemsBox>
            <StyledItem onClick={handleSupportClick}>
              <StyledItemImg>
                <Image src='/img/left-menu/items/support.svg'
                  width={20}
                  height={20}
                />
              </StyledItemImg>

              {t('support_button')}
            </StyledItem>
          </StyledItemsBox>

          <StyledLanguageSelect placement='top' />

          <StyledSocials>
            {socials.map(social => (
              <StyledSocial key={social.link}
                href={social.link}
                target='_blank'
              >
                <StyledSocialImg src={social.icon} />
              </StyledSocial>
            ))}
          </StyledSocials>
        </StyledScrollable>
      </StyledLeftMenuBox>

      {!([SIZES.W688, SIZES.W1040].includes(contentSize) && isNewPage) && (
        <StyledPageOverlay
          onClick={handleToggleLeftMenu}
          active={leftMenuOpened}
        />
      )}

      <InfoModal
        isOpen={infoModalOpened}
        title={t('left_menu_coming_soon')}
        onClose={handleCloseInfoModal}
      >
        <StyledInfoModalContent>
          {t('left_menu_section_under_development')}
        </StyledInfoModalContent>
      </InfoModal>
    </>
  )
}

interface MenuItemsProps {
  items: any[],
  onItemClick: (item: any) => void,
  onToggleClick?: () => void,
  opened?: boolean
}

const MenuItems: React.FC<MenuItemsProps> = ({ items, onItemClick, onToggleClick, opened }) => {
  return (
    <>
      {items.map(item => (
        <React.Fragment key={item.name}>
          <StyledItem
            onClick={() => !!onToggleClick ? onToggleClick() : onItemClick(item)}
            className={classNames({ root: !!item.items, disabled: item.disabled, separateItem: !!item.items })}
          >
            <StyledItemImg className={classNames({ aviator: item.isAviator, disabled: item.disabled })}>
              {
                item.isAviator && (
                  <Image src={item.img}
                    width={30}
                    height={20}
                  />
                )
              }

              {
                !item.isAviator && (
                  <Image src={item.img}
                    width={20}
                    height={20}
                  />
                )
              }
            </StyledItemImg>

            {!!item.bigImg && (
              <StyledItemBigImg className={classNames({ disabled: item.disabled })}>
                <Image src={item.bigImg}
                  width={51}
                  height={22}
                />
              </StyledItemBigImg>
            )}

            {item.name}

            {item.disabled &&
            <StyledLockImg src='/img/left-menu/lock.svg' />
            }

            {!!item.items && (
              <StyledArrow type="dropdown-arrow"
                className={classNames({ rotated: opened, active: opened })}
              />
            )}
          </StyledItem>

          {!!item.items && opened && (
            <MenuItems items={item.items}
              onItemClick={onItemClick}
            />
          )}
        </React.Fragment>
      ))}
    </>
  )
}
