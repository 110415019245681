import { Routes } from "types/routes"
import { CONTACTS, LINKS, Urls } from "utils"

export const CASINO_LINKS = [
  {
    label: 'footer_lobby',
    href: Routes.home
  },
  {
    label: 'footer_slots',
    href: Routes.catalogCategory(4)
  },
  {
    label: 'footer_live',
    href: Routes.catalogLive
  },
  // {
  //   label: 'Crash',
  //   href: Routes.home
  // },
  // {
  //   label: 'Table',
  //   href: Routes.home
  // },
  {
    label: 'footer_casual',
    href: Routes.catalogCategory(5)
  },
  {
    label: 'footer_lottery',
    href: Routes.catalogCategory(6)
  },
]

export const SPORT_LINKS = [
  {
    label: 'footer_sport_main',
    href: Routes.sport
  },
  {
    label: 'footer_sport_live',
    href: Routes.sport + '?bt-path=%2Flive'
  }
]

export const BONUSES_LINKS = [
  {
    label: 'footer_bonuses_cashback',
    href: Routes.bonuses
  },
  {
    label: 'footer_bonuses_rakeback',
    href: Routes.bonuses
  },
  {
    label: 'footer_bonuses_tasks',
    href: Routes.bonuses
  },
  {
    label: 'footer_bonuses_tournaments',
    href: Routes.bonuses
  },
  {
    label: 'footer_bonuses_bonus_games',
    href: Routes.bonuses
  },
  {
    label: 'footer_bonuses_vip',
    href: Routes.bonuses
  },
]

export const INFORMATION_LINKS_1 = [
  {
    label: 'footer_menu_terms_of_service',
    href: Urls.TermsOfService
  },
  {
    label: 'footer_menu_bonuses',
    href: Urls.BonusTerms
  },
  {
    label: 'footer_menu_info',
    href: Urls.Info
  },
  {
    label: 'footer_menu_affiliate',
    href: LINKS.partners
  },
  {
    label: 'footer_menu_self_exclusion',
    href: Urls.PrivacyPolicy
  },
  {
    label: 'footer_menu_fairness',
    href: Urls.FairnessRng
  },
]

export const INFORMATION_LINKS_2 = [
  {
    label: 'footer_menu_privacy_policy',
    href: Urls.PrivacyPolicy
  },
  {
    label: 'footer_menu_user_agreements',
    href: Urls.UserAgreements
  },
  {
    label: 'footer_menu_user_gambling_aware',
    href: Urls.GamblingAware
  },
  {
    label: 'footer_menu_user_kyc_and_aml',
    href: Urls.KycAndAml
  },
  {
    label: 'footer_menu_dispute',
    href: Urls.DisputeResolution
  },
  {
    label: 'footer_general_terms',
    href: '/general_terms_and_conditions'
  },
]

export const PAYMENTS =  Array.from({ length: 12 }).map((_, index) => ({ image: `/img/footer/systems/${index + 1}.png` }))

export const PROVIDERS = Array.from({ length: 35 }).map((_, index) => ({ image: `/img/footer/providers/${index + 1}.png` }))

export const LICENSES = [
  { image: '/img/footer/18.png' },
  { image: '/img/footer/rgc.png' },
  { image: '/img/footer/gamblers-bay.png' },
  { image: '/img/footer/ocg.png' },
  { image: '/img/footer/be-gamble.png' },
]

export const SOCIALS = [
  {
    image: '/img/footer/socials/tg.svg',
    href: CONTACTS.telegram
  },
  {
    image: '/img/footer/socials/google.svg',
    href: CONTACTS.email
  },
  {
    image: '/img/footer/socials/instagram.svg',
    href: CONTACTS.instagram
  },
  // {
  //   image: '/img/footer/socials/discord.svg',
  //   href: CONTACTS.
  // },
  {
    image: '/img/footer/socials/youtube.svg',
    href: CONTACTS.youtube
  },
  {
    image: '/img/footer/socials/facebook.svg',
    href: CONTACTS.facebook
  },
]
