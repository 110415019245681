import { LanguageDropdown } from 'components/layout/Header/components/Profile/LanguageDropdown/LanguageDropdown'
import { LanguageSelect } from 'components/layout/Header/components/Profile/LanguageSelect'
import styled from 'styled-components'
import { Button } from 'ui-kit'
import { BREAKPOINTS, COLORS, FONTS } from 'ui-kit/constants'
import { SvgIcon } from 'ui-kit/svg'
import { applyStyles, SIZES } from 'context/layoutContext'

export const SFooterWrapper = styled.div`
  transition: 0.2s;
  margin-top: 24px;

  ${({ theme }) => applyStyles([SIZES.W688, SIZES.W1040], theme.contentSize, `
    ${(theme.leftMenuOpened && theme.isNewPage) && `
      width: calc(100vw - 277px);
      margin-left: 277px;
    `}
  `)}
`

export const StyledFooterBox = styled.footer`
  width: 100%;
  padding-top: 22px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    padding: 0;
  }
`

export const StyledColoredFooterBox = styled.div`
  width: 100%;
  background: ${COLORS.dark600};
`

export const StyledContent = styled.div`
  margin: 0 auto;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px 58px;

  @media (max-width: ${BREAKPOINTS.sMin + 1}px) {
    max-width: 688px;
  }
`

export const StyledLogo = styled.img`
  width: 88px;
  height: 31px;
`

export const StyledLinksContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 24px 0;
  display: flex;
  border-top: 1px solid ${COLORS.dark500};
  border-bottom: 1px solid ${COLORS.dark500};
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    flex-direction: column;
    padding-top: 0;
  }
`

export const StyledLinksColumn = styled.div`
  display: flex;
  flex-direction: column;

  &.hidden-on-desktop {
    @media (min-width: ${BREAKPOINTS.xsMax}px) {
      display: none;
    }
  }
`

export const StyledLinksDoubleColumnContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: none;
  }
`

export const StyledLinksDoubleColumn = styled.div`
  display: flex;
  gap: 56px;

  @media (max-width: ${BREAKPOINTS.sMin}px) {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(2, 123px);
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledLink = styled.a`
  ${FONTS.p1};
  color: #6B6C77;
  cursor: pointer;

  & ~ & {
    margin-top: 12px;
  }

  &:hover {
    color: white;
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: none;

    &.visible {
      display: block;
    }
  }
`

export const StyledLinksTitle = styled.p`
  color: white;
  ${FONTS.h4};
  margin-bottom: 20px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    margin-bottom: 0;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
  }
`

export const StyledArrowIcon = styled(SvgIcon)`
  color: ${COLORS.dark200};

  &.active {
    transform: rotate(180deg);
    color: white;
  }

  @media (min-width: ${BREAKPOINTS.xsMax}px) {
    display: none;
  }
`

export const StyledText = styled.div`
  margin: 24px 0;
  color: ${COLORS.dark200};
  white-space: pre-line;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  ${FONTS.p1};

  &.full {
    overflow: auto;
    display: initial;
  }
`

export const StyledExpandBtn = styled.button`
  padding: 0;
  width: max-content;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: ${COLORS.dark200};
  ${FONTS.p1};
  text-decoration: underline;

  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
  }
`

export const StyledSocialRow = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.sMin}px) {
    flex-wrap: wrap;
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    gap: 16px;
  }
`

export const StyledStores = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
    margin-left: 0;
    order: 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`

export const StyledStore = styled.a`
  width: 158px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 2px solid #14151B;
  background: white;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
  }
`

export const StyledStoreImg = styled.img`
  width: 108px;
  height: 32px;

  &.ios {
    width: 96px;
    height: 24px;
  }
`

export const StyledSocials = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${BREAKPOINTS.sMin}px) {
    margin-bottom: 24px;
    width: 100%;
    justify-content: center;
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    order: 3;
  }
`

export const StyledSocialBtn = styled.a`
  width: 44px;
  height: 44px;

  &:hover {
    filter: brightness(110%);
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export const StyledProvidersContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  border-top: 1px solid ${COLORS.dark500};
  border-bottom: 1px solid ${COLORS.dark500};
  padding: 44px 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledProvidersRow = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;

  & ~ & {
    margin-top: 40px;
  }
`

export const StyledPaymentsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.dark500};
  padding: 44px 0;
  gap: 20px;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledProviderImg = styled.img`
  height: 32px;
  width: auto;
`

export const StyledLicensesContainer = styled.div`
  padding: 32px 0;
  display: flex;
  align-items: center;
  gap: 22px 56px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 176px;
    flex-wrap: wrap;
  }
`

export const StyledLicenseImg = styled.img`
  height: 60px;
  width: auto;
`

export const StyledBottomText = styled.div`
  ${FONTS.p2};
  color: ${COLORS.dark200};

  a {
    color: ${COLORS.dark200};
  }
`

export const StyledAllRights = styled.p`
  margin-top: 24px;
  ${FONTS.p1};
  color: white;
`

export const StyledLanguagesDropdown = styled(LanguageDropdown)`
  margin-left: 12px;

  .languages-dropdown-toggle {
    height: 44px;
    background: ${COLORS.dark550};
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    display: none;
  }
`

export const StyledLanguageSelect = styled(LanguageSelect)`
  display: none;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    margin-top: 0;
    display: block;
    width: 100%;
  }
`

export const StyledSupportAndLanguagesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    margin-left: 0;
    justify-content: center;
    width: 100%;
    order: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`

export const StyledSupportBtn = styled.button`
  width: 184px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.dark550};
  ${FONTS.a1};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  img {
    margin-right: 8.5px;
  }

  &:hover {
    filter: brightness(110%);
  }

  &:focus, &:active {
    filter: brightness(120%);
  }

  @media (max-width: ${BREAKPOINTS.xsMax - 1}px) {
    width: 100%;
  }
`
